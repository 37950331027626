import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import Layout from '../containers/Layout/Layout'
import Seo from '../components/Seo'
import PageCover from '../components/PageCover'
import Partners from '../components/Partners'
import NewsLetter from '../components/NewsLetter'
import BreadCrumbArrow from '../assets/svgs/breadcrumb-arrow.svg'

import AboutCoverImage from '../assets/images/about-cover.png'
import GenericHeading from '../components/GenericHeading'
import GuyImage from '../assets/images/guy.png'
import FloatingWorkerImage from '../assets/images/engineer-in-refinery.png'

import ExhibitionImageSrc from '../assets/images/about-exhibition-image.png'
import DotsBg from '../assets/images/dots.png'
import MissionVisionCard from '../components/MissionVisionCard'
import MissionIcon from '../assets/svgs/mission.svg'
import VisionIcon from '../assets/svgs/vision.svg'

const AboutSection = tw.section`max-w-screen-xl mx-auto px-5 py-20 sm:px-10 lg:px-14 xl:px-28 md:pt-32 md:pb-37 flex items-center justify-between`;


const AboutContent = tw.section`md:w-6/12 lg:w-about-content`;

const AboutText = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-normal`};
  margin-bottom: ${props => props.marginBottom}
`

const AboutPictureSection = styled.section`
  ${tw`hidden md:flex md:w-4/12 lg:w-5/12 md:h-auto lg:w-about-image lg:h-about-image relative items-end justify-center`}

  background: rgba(37, 74, 196, 0.2);
  border-radius: 10.5097px;
`

const FloatingImage = styled.img`
  ${tw`absolute`}
  bottom: -39px;
  left: -35.21px;
`
const ExhibitionWrapper = tw.div`bg-brand-light-blue-200`;

const ExhibitionSection = tw.section`max-w-screen-xl mx-auto px-5 py-20 sm:px-10 lg:px-14 xl:px-28 xl:pt-41/2 xl:pb-23 md:flex items-center justify-between`;

const ExhibitionImageWrapper = styled.div`
  ${tw`w-full md:w-1/2 pl-5 relative`}

  @media (min-width: 1280px) {
    width: 486px;
  }
`

const ExhibitionContent = styled.div`
  ${tw`mt-10 md:mt-0 w-full md:w-1/2 md:pl-10`};
  @media (min-width: 1280px) {
    width: 450px;
    padding-left: 0;
  }
`

const ExhibitionImageMain = styled.div`
  ${tw`w-full md:w-auto md:rounded-lg relative`}
  height: 279px;
  background: url(${ExhibitionImageSrc}) no-repeat 0, 0 rgba(37, 74, 196, 0.3);
  background-blend-mode: multiply;
  border-radius: 4px;
  z-index: 1;
`;

const DotsImage = styled.img`
  ${tw`absolute`};
  width: 117px;
  height: 132px;
  top: -18px;
  left: -4px;
`
const MissionAndVision = tw.div`bg-brand-light-blue`;
const MissionAndVisionSection = tw.section`max-w-screen-xl mx-auto px-5 py-12 sm:px-10 lg:px-14 xl:px-28 xl:pt-13 xl:pb-17/2`;
const MissionAndVisionSectionRow = styled.section`
  ${tw`grid gap-y-8 lg:gap-y-0 grid-cols-1 big-tablet:grid-cols-2 gap-x-18`}
`;

const ValueSection = tw.section`text-center sm:text-left max-w-screen-xl mx-auto px-5 py-10 sm:px-10 lg:px-14 xl:px-28 xl:py-13`;

const ValuesContainer = tw.div`md:flex items-center justify-between flex-wrap`;

const ValueItemWrapper = tw.div`flex flex-col justify-center items-center mr-0 md:mr-3 mb-10 md:mb-0`
const ValueItemName = tw.p`text-brand-dark-200 tracking-brand font-lato text-center font-bold text-xl leading-brand-small`;


const ValueCard = styled.div`
  ${tw`flex items-center justify-center`}
  width: 100px;
  height: 100px;
  background: #fefefe;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-bottom: 15px;
`


const About = () => {
  return (
    <Layout>
      <Seo title="About Us"/>
      <PageCover
        imageSrc={AboutCoverImage}
        coverTitle="About Us"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/about-us">About Us</Link>
      </PageCover>
      <AboutSection>
        <AboutContent>
          <GenericHeading marginBottom="2.8125rem" heading="About Us" />
          <AboutText marginBottom="2.8125rem">
            Manz Incorporated Limited is a registered company under the law of the Federal Republic of Nigeria and the Allied Matter&apos;s act to render services to within Energy and Oil & Gas industries.
          </AboutText>
        </AboutContent>
        <AboutPictureSection>
          <img src={GuyImage} alt="An onsite worker"/>
          <FloatingImage src={FloatingWorkerImage} alt="A worker standing" />
        </AboutPictureSection>
      </AboutSection>
      <ExhibitionWrapper>
        <ExhibitionSection>
          <ExhibitionImageWrapper>
            <ExhibitionImageMain />
            <DotsImage src={DotsBg} alt="Dots" />
          </ExhibitionImageWrapper>
          <ExhibitionContent>
            <AboutText>
              We have consistently continued to respond to this objective and keeping pace with new innovation and applications in engineering technology and project managements for articulate, efficient result-oriented solutions.
            </AboutText>
          </ExhibitionContent>
        </ExhibitionSection>
      </ExhibitionWrapper>
      <MissionAndVision>
        <MissionAndVisionSection>
          <GenericHeading marginBottom="3.875rem" heading="Our Mission and Vision" />
          <MissionAndVisionSectionRow>
            <MissionVisionCard
              cardTitle="Vision"
              cardContent="To be the foremost provider of engineering, procurement and construction services in Africa, while harnessing innovative technological solutions that are safe to our industry and environment."
            >
              <VisionIcon />
            </MissionVisionCard>
            <MissionVisionCard
              cardTitle="Mission"
              cardContent="We are set out to utilize safely, competent personnel and resources in delivering highest quality engineering solutions and client focused values to operational and maintenance challenges within our industry."
            >
              <MissionIcon />
            </MissionVisionCard>
          </MissionAndVisionSectionRow>
        </MissionAndVisionSection>
      </MissionAndVision>
      <ValueSection>
        <GenericHeading marginBottom="4.125rem" heading="Our Core Values" />
        <ValuesContainer>
          <ValueItemWrapper>
            <ValueCard>
              <svg width="38" height="38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.268 9.56a4.283 4.283 0 100-8.565 4.283 4.283 0 000 8.565zM5.278 37.55a4.283 4.283 0 100-8.565 4.283 4.283 0 000 8.565zM5.278 9.56a4.283 4.283 0 100-8.565 4.283 4.283 0 000 8.565zM33.268 37.55a4.283 4.283 0 100-8.565 4.283 4.283 0 000 8.565zM15.488 15.487a3.202 3.202 0 01-3.65.621l-3.164 3.165 4.542 4.542-.757.757a1.06 1.06 0 000 1.514 1.06 1.06 0 001.514 0l.757-.757 4.543 4.543 3.164-3.165a3.202 3.202 0 01.621-3.649 3.202 3.202 0 013.65-.62l3.164-3.165-4.543-4.543.758-.757a1.06 1.06 0 000-1.514 1.06 1.06 0 00-1.515 0l-.757.757-4.542-4.542-3.165 3.164a3.202 3.202 0 01-.62 3.65z" fill="#254AC4"/><path d="M31.755 21.154l-.368-.367-4.543 4.542-.757-.757a1.06 1.06 0 00-1.514 0 1.06 1.06 0 000 1.514l.757.757-4.542 4.543.367.367a5.355 5.355 0 007.571 0l3.029-3.028a5.355 5.355 0 000-7.57zM6.792 17.39l.368.368 4.542-4.542.757.757a1.06 1.06 0 001.514 0 1.06 1.06 0 000-1.514l-.757-.757 4.543-4.543-.368-.368a5.355 5.355 0 00-7.571 0L6.792 9.82a5.355 5.355 0 000 7.57zM23.952 10.324a3.202 3.202 0 013.649.62 3.202 3.202 0 01.62 3.65l3.165 3.164.368-.368a5.355 5.355 0 000-7.57L28.726 6.79a5.355 5.355 0 00-7.571 0l-.368.368 3.165 3.165zM17.39 31.753l.369-.367-3.165-3.165a3.202 3.202 0 01-3.649-.62 3.202 3.202 0 01-.62-3.65L7.16 20.787l-.368.367a5.355 5.355 0 000 7.571l3.028 3.029a5.355 5.355 0 007.57 0z" fill="#254AC4"/></svg>
            </ValueCard>
            <ValueItemName>Teamwork</ValueItemName>
          </ValueItemWrapper>
          <ValueItemWrapper>
            <ValueCard>
              <svg width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.085 21.053c-1.122.191-2.268.191-3.39 0a9.87 9.87 0 001.695 10.393 9.87 9.87 0 001.695-10.393zM25.92 15.065a9.784 9.784 0 00-7.655 2.436c.589.654 1.099 1.376 1.518 2.15a9.933 9.933 0 006.137-4.586zM16.292 19.896a9.38 9.38 0 002.195 0 9.925 9.925 0 00-1.097-1.516 9.96 9.96 0 00-1.098 1.516z" fill="#254AC4"/><path d="M9.956 13.764a11.092 11.092 0 017.433 2.863 10.906 10.906 0 019.127-2.72 9.91 9.91 0 10-18.255 0c.56-.091 1.127-.139 1.695-.143zM9.957 34.823c2.42 0 4.753-.89 6.559-2.5a11.064 11.064 0 01-2.044-11.527 11.189 11.189 0 01-6.907-5.49 9.904 9.904 0 002.392 19.517zM20.309 20.796c1.573 3.917.78 8.39-2.044 11.527a9.9 9.9 0 108.95-17.016 11.19 11.19 0 01-6.906 5.489z" fill="#254AC4"/><path d="M8.86 15.065a9.933 9.933 0 006.136 4.586c.42-.774.93-1.496 1.52-2.15a9.784 9.784 0 00-7.657-2.436z" fill="#254AC4"/></svg>
            </ValueCard>
            <ValueItemName>Integrity</ValueItemName>
          </ValueItemWrapper>
          <ValueItemWrapper>
            <ValueCard>
              <svg width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.048 15.248c0-.559-.453-1.011-1.012-1.011H1.012a1.012 1.012 0 100 2.024h2.024c.56 0 1.012-.453 1.012-1.012zM33.533 14.236H31.51a1.012 1.012 0 100 2.025h2.024a1.012 1.012 0 100-2.024zM5.51 7.287L3.755 6.275A1.012 1.012 0 102.744 8.03L4.497 9.04A1.013 1.013 0 005.51 7.287zM31.802 22.468l-1.753-1.012a1.012 1.012 0 10-1.012 1.753l1.753 1.013a1.013 1.013 0 001.012-1.754zM9.312 3.271a1.013 1.013 0 001.753-1.012L10.053.506A1.012 1.012 0 108.3 1.518L9.312 3.27zM4.497 21.456l-1.753 1.012a1.012 1.012 0 101.012 1.754l1.753-1.013a1.012 1.012 0 10-1.012-1.753zM30.049 9.04l1.753-1.011a1.012 1.012 0 10-1.012-1.754l-1.753 1.012a1.012 1.012 0 101.012 1.754zM23.851 3.642c.481.278 1.101.116 1.383-.37l1.012-1.754A1.012 1.012 0 1024.493.506L23.48 2.259a1.012 1.012 0 00.37 1.383zM24.292 6.606c-2.615-2.125-6.04-2.943-9.404-2.241-4.369.91-7.816 4.493-8.577 8.913-.638 3.702.583 7.445 3.264 10.014 1.427 1.364 2.294 3.19 2.533 5.113h4.153v-4.754l-3.823-5.734-1.953-1.953a1.012 1.012 0 111.431-1.431l1.31 1.309 1.308-1.31a1.012 1.012 0 011.43 0l1.31 1.31 1.308-1.31a1.012 1.012 0 011.431 0l1.309 1.31 1.308-1.31a1.012 1.012 0 111.431 1.432l-1.953 1.953-3.823 5.734v4.754h4.096c.118-1.818.981-3.599 2.522-5.05a11.18 11.18 0 003.503-8.107c0-3.366-1.5-6.516-4.114-8.642z" fill="#254AC4"/><path d="M19.297 16.68l-1.308 1.308a1.012 1.012 0 01-1.431 0l-1.309-1.308-.722.721 2.746 4.12 2.746-4.12-.721-.721zM12.213 31.509a3.04 3.04 0 003.036 3.036h4.048a3.04 3.04 0 003.037-3.036v-1.08H12.213v1.08z" fill="#254AC4"/></svg>
            </ValueCard>
            <ValueItemName>Innovation</ValueItemName>
          </ValueItemWrapper>
          <ValueItemWrapper>
            <ValueCard>
              <svg width="35" height="39" viewBox="0 0 35 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.928 14.816a3.174 3.174 0 100-6.348 3.174 3.174 0 000 6.348zM19.828 25.498a2.035 2.035 0 100-4.071 2.035 2.035 0 000 4.071z" fill="#254AC4"/><path d="M30.988 16.772C33.546 4.252 23.374-1.478 12.085.326.794 2.126 1.218 8.956.553 12.215-.769 20.383 7.64 29.51 7.64 29.51L7.16 39h13.692l1.048-4.363s.594.997 4.132 1.297c3.539.3 3.108-4.02 3.108-4.02s2.356-1.04 2.356-1.818c0-.78-1.394-1.786-1.394-1.786s1.498.269 2.04-.362c.54-.63-.661-2.91-.661-2.91s1.848-.047 2.936-1.245c1.089-1.196-3.428-4.74-3.428-7.02zm-19.555 1.712l-.114-1.269c-.34-.09-.669-.21-.984-.351l-1.118.947-.19.105c-.064.034-1.25-.105-1.25-.105l-2.04-2.409v-1.438l1.177-.997a5.255 5.255 0 01-.16-.99l-1.54-.705-.413-1.198.048-.196 1.317-2.869 1.392-.366 1.328.606c.27-.218.562-.417.87-.591-.045-.534-.131-1.602-.131-1.602l1.018-1.017 3.145-.254 1.018 1.018s.065.812.099 1.217c.41.111.8.258 1.17.438L17.1 5.58h1.44l2.055 2.396v1.438l-1.25 1.07c.061.291.094.591.109.894l1.578.911.366 1.393-1.58 2.731-1.392.366s-.817-.473-1.227-.707c-.23.18-.478.338-.734.486l.147 1.636-1.017 1.018-3.14.286-1.02-1.015zm12.812 8.04l-.893.236s-.524-.304-.787-.454a4.144 4.144 0 01-.473.31l.095 1.049-.651.652-2.014.184-.653-.653-.074-.814a4.255 4.255 0 01-.63-.226c-.238.203-.717.607-.717.607s-.082.044-.121.069c-.04.024-.803-.07-.803-.07l-1.307-1.546v-.924l.754-.639a3.415 3.415 0 01-.102-.636l-.989-.452-.264-.766.03-.127.844-1.839.894-.235s.567.258.85.39c.174-.141.361-.267.559-.38l-.083-1.03.653-.652 2.016-.162.654.652s.04.52.062.781c.262.072.514.166.751.281l.657-.563h.925l1.32 1.54v.924l-.804.687c.04.186.06.378.07.574l1.013.584.234.894-1.016 1.755z" fill="#254AC4"/></svg>
            </ValueCard>
            <ValueItemName>Professionalism</ValueItemName>
          </ValueItemWrapper>
          <ValueItemWrapper>
            <ValueCard>
              <svg width="37" height="37" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" fill="#254AC4"><path d="M28.943 23.91a8.047 8.047 0 01-6.513 3.323h-3.752c-1.296 0-2.4-.823-2.818-1.974a9.516 9.516 0 01-1.516-.6 10.206 10.206 0 01-2.83-2.095c-5.422 1.191-9.48 6.022-9.48 11.8v.756A1.88 1.88 0 003.915 37h29.172a1.88 1.88 0 001.88-1.88v-.755c0-4.465-2.422-8.363-6.023-10.455z"/><path d="M9.515 18.689a2.14 2.14 0 001.876-1.109l.029.078.008.024c.855 2.25 2.519 4.136 4.712 4.96a2.995 2.995 0 012.538-1.403h3.752c.375 0 .723-.106 1.028-.277.513-.286.975-1.014 1.213-1.4.38-.617.676-1.27.938-1.983.147.267.35.5.592.682v.92c0 2.079-1.692 3.77-3.77 3.77h-3.753a1.285 1.285 0 000 2.57h3.752a6.347 6.347 0 006.34-6.34v-.92c.52-.39.856-1.013.856-1.713v-5.359c0-.714-.35-1.347-.889-1.736C28.32 4.171 23.889 0 18.5 0S8.68 4.17 8.263 9.453a2.137 2.137 0 00-.889 1.736v5.359c0 1.186.963 2.14 2.14 2.14zM18.5 2.569c3.989 0 7.279 3.048 7.663 6.937-.252.2-.46.453-.602.744-1.212-3.162-3.917-5.364-7.06-5.364-3.212 0-5.875 2.277-7.058 5.351l-.004.013a2.149 2.149 0 00-.602-.743c.385-3.89 3.675-6.938 7.663-6.938z"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h37v37H0z"/></clipPath></defs></svg>
            </ValueCard>
            <ValueItemName>Superior customer service</ValueItemName>
          </ValueItemWrapper>
        </ValuesContainer>
      </ValueSection>
      <Partners color="blue"/>
      <NewsLetter color="white"/>

    </Layout>
  )
}

export default About