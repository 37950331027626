import React from 'react'
import tw, { styled } from 'twin.macro'

const Card = styled.article`
  ${tw`sm:flex p-5 items-center justify-between bg-white`}
  box-shadow: 0px 10.7716px 32.3147px 5.43475px rgba(0, 0, 0, 0.02);
  border-radius: 5.74484px;

  @media only screen and (min-width: 640px) {
    padding: 19.93px 23.55px 23.55px 31.7px;
  }

  > *:first-child {
    margin-right: 31px;
  }
`

const IconContainer = tw.div`mb-4 sm:mb-0`;
const ContentContainer = tw.div``;
const CardTitle = tw.h3`font-mulish font-extrabold tracking-brand text-7/4 leading-tight text-brand-dark-200 mb-4`
const CardContent = tw.p`font-lato text-base leading-tight mb-2 text-brand-dark-200 text-justify`;

const MissionVisionCard = props => {
  return (
    <Card>
      <IconContainer>
        {props.children}
      </IconContainer>
      <ContentContainer>
        <CardTitle>
          {props.cardTitle}
        </CardTitle>
        <CardContent>
          {props.cardContent}
        </CardContent>
      </ContentContainer>

    </Card>
  )
}

export default MissionVisionCard